import '@css/main.scss';
import '@js/bootstrap';
import { Loader } from '@js/utils/loader';

import {Accordion} from '@js/components/accordion';
import {Header} from '@js/components/Header';
import {Count} from '@js/components/Count';
// import {Lightbox} from '@js/components/Lightbox';

import AOS from 'aos';
import 'aos/dist/aos.css';

const scripts = [
  new Accordion(),
  new Header(),
  new Count(),
  // new Lightbox(),
];

window.onload = () => {
    AOS.init({
        offset: -50,
        delay: 0,
    });

    (new Loader()).load(scripts)
}
