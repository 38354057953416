import gsap from 'gsap'

export class Header {
  constructor(container)
  {
    this.body = document.querySelector('body')
    this.header = document.querySelector('body header.banner')
    if (this.header) {
      this.nav = this.header.querySelector('.menu-menu-principale-container')

      if (container) {
        container.on('scroll', evt => {
          if (evt.scroll.y > 100) {
            this.header.classList.add('is-visible')
          } else {
            this.header.classList.remove('is-visible')
          }
        });
      } else {
        window.addEventListener('scroll', evt => {
          if (window.scrollY > 100) {
            this.header.classList.add('is-visible')
          } else {
            this.header.classList.remove('is-visible')
          }
        });
      }

      let mm = gsap.matchMedia(),
        breakPoint = 992;

      mm.add({
        isDesktop: `(min-width: ${breakPoint}px) and (prefers-reduced-motion: no-preference)`,
        isMobile: `(max-width: ${breakPoint - 1}px) and (prefers-reduced-motion: no-preference)`
      }, (context) => {
        let { isDesktop, isMobile } = context.conditions

        if (isMobile) {
          this.tl = gsap.timeline({paused:true});
          this.animation()

          const burger = document.querySelector('#burger')
          if (burger) {
            burger.addEventListener('click', evt => {
              evt.preventDefault()
              burger.classList.toggle('active')
              this.body.setAttribute('o-hidden', '')
              this.header.classList.toggle('expanded')

              if (this.header.classList.contains('expanded')) {
                this.tl.play()
                this.body.removeAttribute('o-hidden')
              } else {
                this.tl.reverse()
              }
            })
          }

          if (this.nav) {
            this.nav.querySelectorAll('.menu-item.menu-item-has-children > a').forEach(item => {
              const tl = gsap.timeline({paused:true});
              const parent = item.closest('.menu-item');
              this.animationItems(parent, tl)

              item.addEventListener('click', evt => {
                evt.preventDefault()
                parent.classList.toggle('active')

                if (parent.classList.contains('active')) {
                  tl.play()
                } else {
                  tl.reverse()
                }
              })
            })
          }
        }

        return () => {
        }
      });
    }
  }

  animation()
  {
    const menu_items = this.nav.querySelectorAll('.nav > .menu-item')

    this.tl.to(this.nav, {x: 0})
    this.tl.fromTo(menu_items, {x: 15, opacity: 0}, {x: 0, opacity: 1, stagger: 0.15})
  }

  animationItems(parentItem, tl)
  {
    const container = parentItem.querySelector('.sub-menu')
    if (container) {
      const menu_items = container.querySelectorAll(':scope > .menu-item')

      tl.fromTo(container, {display: 'none', visibility: 0}, {display: 'flex', visibility: 1})
      tl.fromTo(menu_items, {x: -15, opacity: 0}, {x: 0, opacity: 1, stagger: 0.15}, '-=.5')
    }
  }

}
