export class Accordion
{
    constructor()
    {
        document.querySelectorAll('.s-button-list .c-button').forEach((btn) => {
            const accordion = document.querySelector(btn.getAttribute('href'));

            if (accordion) {
                btn.addEventListener('click', (e) => {
                    e.preventDefault();
                    accordion.setAttribute('open', "");
                    accordion.scrollIntoView({behavior: "smooth", block: "center", inline: "start"});
                });
            }
        });
    }
}
